import { LoadingModuleOptions } from '@equinor/echo-base';
import '@equinor/echo-components/style-reset.css';
import EchoCore, { createEchoAppModuleApi, queryClient, EventHubProvider as TypedEventHubProvider } from '@equinor/echo-core';
import { mainMenu, Mediator, searchPanel } from '@equinor/echo-framework';
import { Icon } from '@equinor/eds-core-react';
import * as Icons from '@equinor/eds-icons';
import React from 'react';
import { initForE2ETests } from './utils/setupSkipAuth';

import { QueryClientProvider } from '@tanstack/react-query';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as TypedBrowserRouter } from 'react-router-dom';
import { EchoApp } from './app';

/**
 * TODO: further investigation needed.
 * Getting these type errors for React components, which have children.
 * Same setup works in echopediaWeb. Maybe it will be resolved with a next version bump?
 * Doing a workaround now: setting them to 'any' type.
 *
 * TS2786: 'BrowserRouter' cannot be used as a JSX component.
    Its type 'typeof BrowserRouter' is not a valid JSX element type.
    Type 'typeof BrowserRouter' is not assignable to type 'new (props: any, deprecatedLegacyContext?: any) => Component<any,
    any, any>'.
    Construct signature return types 'BrowserRouter' and 'Component<any, any, any>' are incompatible.
    The types returned by 'render()' are incompatible between these types.
 */
const BrowserRouter = TypedBrowserRouter as any;
const EventHubProvider = TypedEventHubProvider as any;

const useEdsIcon = (): void => {
    Icon.add({
        ...Icons
    });
};

const Echo: React.FC = (): JSX.Element => {
    /* Needed to skip authentication when running e2e tests*/
    initForE2ETests();

    const isAuthenticated = EchoCore.useEchoSetup({
        leftPanels: [searchPanel],
        rightPanel: mainMenu
    });
    useEdsIcon();
    const moduleOptions: LoadingModuleOptions = {
        createApi: createEchoAppModuleApi(),
        dependencies: {
            react: require('react'),
            'react-dom': require('react-dom'),
            'react-router-dom': require('react-router-dom'),
            '@equinor/echo-core': require('@equinor/echo-core'),
            '@equinor/echo-framework': require('@equinor/echo-framework'),
            '@equinor/echo-utils': require('@equinor/echo-utils'),
            '@equinor/echo-components': require('@equinor/echo-components'),
            '@equinor/echo-base': require('@equinor/echo-base'),
            '@equinor/echo-search': require('@equinor/echo-search'),
            '@equinor/eds-core-react': require('@equinor/eds-core-react'),
            '@equinor/eds-icons': require('@equinor/eds-icons'),
            'styled-components': require('styled-components'),
            classnames: require('classnames'),
            lodash: require('lodash'),
            zustand: require('zustand')
        },
        fetchModules: () => {
            return new Promise((resolve) => {
                fetch('/echoModuleManifest.json').then((response) => {
                    response.json().then((manifests) => {
                        manifests.forEach((manifest) => {
                            manifest.fileUri = `/index.js`;
                        });
                        resolve(manifests);
                    });
                });
            });
        }
    };
    return (
        <>
            {isAuthenticated && (
                <EchoCore.AuthProviderComponent>
                    <QueryClientProvider client={queryClient}>
                        <EventHubProvider>
                            <Mediator options={moduleOptions} />
                            <BrowserRouter>
                                <EchoApp />
                            </BrowserRouter>
                        </EventHubProvider>
                    </QueryClientProvider>
                </EchoCore.AuthProviderComponent>
            )}
        </>
    );
};

if (!(window !== window.parent && !window.opener)) {
    const container = document.getElementById('root') as HTMLElement;
    const root = createRoot(container);
    root.render(<Echo />);
}
