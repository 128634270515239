// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Applied in echo application by bootstrap so it should exist here to */
*,
:after,
:before {
    box-sizing: border-box;
}

#root {
    height: 100%;
    overflow: hidden;
    width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/app.css"],"names":[],"mappings":"AAAA,wEAAwE;AACxE;;;IAGI,sBAAsB;AAC1B;;AAEA;IACI,YAAY;IACZ,gBAAgB;IAChB,WAAW;AACf","sourcesContent":["/* Applied in echo application by bootstrap so it should exist here to */\r\n*,\r\n:after,\r\n:before {\r\n    box-sizing: border-box;\r\n}\r\n\r\n#root {\r\n    height: 100%;\r\n    overflow: hidden;\r\n    width: 100%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
