import { EchoEnv, useInitial } from '@equinor/echo-core';
import { EchoContent, EchoEventHandler, EchoRoutes, Toasters } from '@equinor/echo-framework';
import { Syncer } from '@equinor/echo-search';
import { fireAndForget } from '@equinor/echo-utils';
import React from 'react';
import { Redirect as TypedRedirect, Route as TypedRoute, Switch as TypedSwitch } from 'react-router-dom';
import { getCorePlants } from './api/api-plants';
import './app.css';
import { Home } from './components/Home/Home';
import { Legend } from './components/legend';

/**
 * TODO: further investigation needed.
 * Getting these type errors for React components, which have children.
 * Same setup works in echopediaWeb. Maybe it will be resolved with a next version bump?
 * Doing a workaround now: setting them to 'any' type.
 * 
 * TS2786: 'BrowserRouter' cannot be used as a JSX component.
    Its type 'typeof BrowserRouter' is not a valid JSX element type.
    Type 'typeof BrowserRouter' is not assignable to type 'new (props: any, deprecatedLegacyContext?: any) => Component<any,
    any, any>'.
    Construct signature return types 'BrowserRouter' and 'Component<any, any, any>' are incompatible.
    The types returned by 'render()' are incompatible between these types.
 */
const Switch = TypedSwitch as any; // TS has an issue with the return type
const Route = TypedRoute as any;
const Redirect = TypedRedirect as any;

export const EchoApp: React.FC = () => {
    useInitial(async () => {
        await Syncer.configuration.setApiBaseUrl(EchoEnv.env().REACT_APP_API_URL);
        fireAndForget(getCorePlants);
    });

    return (
        <EchoEventHandler>
            <EchoContent Legend={Legend}>
                <Switch>
                    <EchoRoutes homeComponent={Home} />
                    <Route render={(): JSX.Element => <Redirect to="/" />} />
                </Switch>
                <Toasters />
            </EchoContent>
        </EchoEventHandler>
    );
};
